<template>
    <div>
        <form :action="url" method="POST">
            <input type="hidden" name="amount" v-model="formData.amount" >
            <input type="hidden" name="tax_amount" v-model="formData.tax_amount" >
            <input type="hidden" name="total_amount" v-model="formData.total_amount" >
            <input type="hidden" name="transaction_uuid" v-model="formData.transaction_uuid" >
            <input type="hidden" name="product_code" v-model="formData.product_code" >
            <input type="hidden" name="product_service_charge" v-model="formData.product_service_charge" >
            <input type="hidden" name="product_delivery_charge" v-model="formData.product_delivery_charge" >
            <input type="hidden" name="success_url" v-model="formData.success_url" >
            <input type="hidden" name="failure_url" v-model="formData.failure_url" >
            <input type="hidden" name="signed_field_names" v-model="formData.signed_field_names" >
            <input type="hidden" name="signature" v-model="signature" >
            <button class="univ-btn w-100 mt-4" > Esewa Proceed [ {{ currency }} {{ formData.total_amount }} ]</button>
        </form> 
    </div>
    
</template>
<script>
import api from "@/services/api"
import store from "@/store"
import CryptoJS from 'crypto-js'
export default {
    name:"Esewa",
    props:{
        typeData:{
            required:true,
            type:[Array,Object],
        }
    },
    data(){
        return {
            formData:"",
            url: api.getEsewaUrl('/epay/main/v2/form'),
            signature:"",
            currency: store.getters.currency,
        }
    },
    watch:{
        typeData(value){
            let app = this;
            app.formData = value;
            app.url = api.getEsewaUrl('/epay/main/v2/form');
            let hash = CryptoJS.HmacSHA256('total_amount='+value.total_amount+',transaction_uuid='+value.transaction_uuid+',product_code='+value.product_code+'','8gBm/:&EnhH.1/q');//secret key for 
            app.signature = CryptoJS.enc.Base64.stringify(hash);
        }
    }
}
</script>